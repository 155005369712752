import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CrudService } from "./crud.service";
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

export enum EnumFluxoProcesso {
  FLX_COMPRA_NORMAL = 'Compra Normal',
  FLX_DEVOLUCAO_VENDA = 'Devolução de venda',
  FLX_ENT_TRANSF_CENTROS = 'Entrada de transferência entre centros',
  FLX_NOTA_AVULSA = 'Nota avulsa',
  FLX_RETORNO_REMESSA = 'Retorno de remessa',
  FLX_FUTURA_FAT = 'Entrega Futura - Fatura',
  FLX_FUTURA_REM = 'Entrega Futura - Remessa',
  FLX_TRIANG_FAT = 'Triangulação - Fatura',
  FLX_TRIANG_REM = 'Triangulação - Remessa',
  FLX_SUBC_RET_ACABADO = 'Subcontratação - Retorno material acabado',
  FLX_SUBC_RET_NAO_UTILIZADOS = 'Subcontratação - Retorno não utilizados',
  FLX_SUBC_RET_SIMBOLICO = 'Subcontratação - Retorno simbólico',
  FLX_PROCESSO_EXTERNO =  'Processo Externo',
}

export interface IdentificadorProcesso {
  cnpj_fornecedor?: string,
  cnpj_destinatario?: string,
  cfop: string;
  ncm: string;
  nome: string;
  processo: string;
  uuid?: string;
  uuid_cliente?: string;
}

// Os fluxos não utilizados não devem constar do Enum, pq a tela de cadastro é baseada
//   nesse enum
//    'Entrega Futura' = 'FLX_ENTREGA_FUTURA',
//    'Operação Triangular' = 'FLX_OPERACAO_TRIANGULAR',
//    'Subcontratação' = 'FLX_SUBCONTRATACAO',

@Injectable({
  providedIn: 'root'
})
export class IdentificadorProcessoService extends CrudService {

  private cacheIdProcs: IdentificadorProcesso[];
  private lastCheck = Date.now();

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  // Endpoint do serviço
  get apiEndpoint() {
    return "identificador-processo";
  }

  /**
   * Método para recuperar identificadores de processo
   *
   * @param fluxo any
   * @returns any
   */
  getByFluxo(fluxo?: string): Observable<IdentificadorProcesso[]> {
    // Caso seja uma requisição valida para listagem
    if (this.validRequestList) {
      const params = new HttpParams().set('fluxo', fluxo);
      // Lista
      return this.list(params);
    }
    // Retorna um observable do cache
    return this.getProcsAtual(fluxo);
  }

  /**
   * Método para regastar o cache de tolerâncias filtrado ou não
   * @param fluxo tipo de fluxo
   * @returns observable
   */
  getProcsAtual(fluxo?: string): Observable<IdentificadorProcesso[]> {
    return new Observable((observer: any) => {
      // Cópia do objeto de tolerâncias
      let filteredProcs = [...this.cacheIdProcs];
      // Caso tenha o filtro do fluxo
      if (fluxo) {
        // Filtra dentro do cache o tipo de processo selecionado
        filteredProcs = filteredProcs.filter((f) => f.processo === fluxo);
      }
      // Lança a resposta para o observer
      observer.next(filteredProcs);
      // Completa o observer
      observer.complete();
    });
  }

  /**
   * Verifica se há necessidade de enviar requisição de lista
   */
  get validRequestList(): boolean {
    // Data atual em millisegundos
    const date = Date.now();
    // 5 minutos em millisegundos
    const interval = 60 * 1000 * 5;
    // Caso não tenha valor ou se a data da última checagem já ultrapassou 5 minutos
    if (!this.cacheIdProcs || date > this.lastCheck + interval) {
      // Atualiza a ultima checagem para data atual
      this.lastCheck = Date.now();
      // Retorna verdadeiro para enviar a requisição ao backend
      return true;
    }
  }

  /**
   * Método para atualizar uma entidade
   *
   * @param body corpo
   * @param id id
   * @returns observer
   */
  public update(body: any, id: any) {
    return super.update(body, id).pipe(switchMap(r => this.init(true)));
  }

  /**
   * Método para excluir uma entidade
   *
   * @param id id
   * @returns observer
   */
  public delete(id: any) {
    return super.delete(id).pipe(switchMap(r => this.init(true)));

  }

  /**
   * Método que inicializa a lista de tolerâncias
   * @returns lista
   */
  public async init(force?: boolean) {
    // Envia a requisição caso esteja sendo forçado, ou caso seja uma request válida
    if (force || this.validRequestList) {
      return this.list(null).subscribe(
        procs => {
          this.cacheIdProcs = procs;
        });
    }
  }

  /**
   * Recupera um fluxo de processo com base no cfop e ncm
   * @returns fluxoProcesso
   */
  public getFluxo(search: { cfop: string, ncm: string, fluxo?: string, cnpjFornecedor?: string, cnpjDestinatario?: string }): IdentificadorProcesso | null | undefined {

    // Se existe fluxo atribuido ao processo, deve ser considerado ao invés da determinação em runtime
    if (search.fluxo && search.fluxo != '') {
      const ret: IdentificadorProcesso = {
        cfop: search.cfop,
        ncm: search.ncm,
        processo: search.fluxo,
        nome: search.fluxo,
      }
      return ret
    }

    // Busca primeiro pelo CFOP, CNPJ Forn, CNPJ Dest e NCM:
    let idProc = this.cacheIdProcs.find(idProcFind =>
      idProcFind.cfop === search.cfop &&
      idProcFind.cnpj_fornecedor === search.cnpjFornecedor &&
      idProcFind.cnpj_destinatario === search.cnpjDestinatario &&
      (search.ncm != null && search.ncm.startsWith(idProcFind.ncm))
    );

    // Após isso busca apenas pelo CFOP, CNPJ Forn e NCM:
    if (!idProc) {
      idProc = this.cacheIdProcs.find(idProcFind =>
        idProcFind.cfop === search.cfop &&
        idProcFind.cnpj_fornecedor === search.cnpjFornecedor &&
        !idProcFind.cnpj_destinatario &&
        (search.ncm != null && search.ncm.startsWith(idProcFind.ncm))
      );
    }

    // Após isso busca apenas pelo CFOP e CNPJ onde não existe NCM:
    if (!idProc) {
      idProc = this.cacheIdProcs.find(idProcFind =>
        idProcFind.cfop === search.cfop &&
        idProcFind.cnpj_fornecedor === search.cnpjFornecedor &&
        idProcFind.cnpj_destinatario === search.cnpjDestinatario &&
        !idProcFind.ncm
      );
    }

    // Após isso busca apenas pelo CFOP e CNPJ do Fornecedor onde não existe NCM:
    if (!idProc) {
      idProc = this.cacheIdProcs.find(idProcFind =>
        idProcFind.cfop === search.cfop &&
        idProcFind.cnpj_fornecedor === search.cnpjFornecedor &&
        !idProcFind.cnpj_destinatario &&
        !idProcFind.ncm
      );
    }

    // Após isso busca apenas pelo CFOP e CNPJ do Destinatário
    if (!idProc) {
      idProc = this.cacheIdProcs.find(idProcFind =>
        idProcFind.cfop === search.cfop &&
        !idProcFind.cnpj_fornecedor &&
        idProcFind.cnpj_destinatario === search.cnpjDestinatario &&
        !idProcFind.ncm
      );
    }

    // Após isso busca apenas pelo CFOP e NCM:
    if (!idProc) {
      idProc = this.cacheIdProcs.find(idProcFind =>
        idProcFind.cfop === search.cfop &&
        !idProcFind.cnpj_fornecedor &&
        !idProcFind.cnpj_destinatario &&
        (search.ncm != null && search.ncm.startsWith(idProcFind.ncm))
      );
    }

    // Após isso busca apenas pelo CFOP onde não existe NCM:
    if (!idProc) {
      idProc = this.cacheIdProcs.find(idProcFind =>
        idProcFind.cfop === search.cfop &&
        !idProcFind.cnpj_fornecedor &&
        !idProcFind.cnpj_destinatario &&
        !idProcFind.ncm
      );
    }
    return idProc;
  }

  private checkFluxo(search: { cfop: string, ncm: string, fluxo?: string, cnpjFornecedor?: string, cnpjDestinatario?: string }, fluxo: string): boolean {
    const idProc = this.getFluxo(search);
    return idProc?.processo === fluxo;
  }

  public fluxoSubcontratacao(cfop: string, ncm?: string, fluxo?: string, cnpjFornecedor?: string, cnpjDestinatario?: string): boolean {
    return this.fluxoSubcRetornoAcabado(cfop, ncm, fluxo, cnpjFornecedor, cnpjDestinatario) || this.fluxoSubcRetornoSimbolico(cfop, ncm, fluxo, cnpjFornecedor, cnpjDestinatario);
  }

  public fluxoSubcRetornoAcabado(cfop: string, ncm?: string, fluxo?: string, cnpjFornecedor?: string, cnpjDestinatario?: string): boolean {
    return this.checkFluxo({ cfop, ncm, fluxo, cnpjFornecedor, cnpjDestinatario }, 'FLX_SUBC_RET_ACABADO');
  }

  public fluxoSubcRetornoNaoUtilizado(cfop: string, ncm?: string, fluxo?: string, cnpjFornecedor?: string, cnpjDestinatario?: string): boolean {
    return this.checkFluxo({ cfop, ncm, fluxo, cnpjFornecedor, cnpjDestinatario }, 'FLX_SUBC_RET_NAO_UTILIZADOS');
  }

  public fluxoSubcRetornoSimbolico(cfop: string, ncm?: string, fluxo?: string, cnpjFornecedor?: string, cnpjDestinatario?: string): boolean {
    return this.checkFluxo({ cfop, ncm, fluxo, cnpjFornecedor, cnpjDestinatario }, 'FLX_SUBC_RET_SIMBOLICO');
  }

  public fluxoNFeFuturaFatura(cfop: string, ncm?: string, fluxo?: string, cnpjFornecedor?: string, cnpjDestinatario?: string): boolean {
    return this.checkFluxo({ cfop, ncm, fluxo, cnpjFornecedor, cnpjDestinatario }, 'FLX_FUTURA_FAT');
  }

  public fluxoNFeFuturaRemessa(cfop: string, ncm?: string, fluxo?: string, cnpjFornecedor?: string, cnpjDestinatario?: string): boolean {
    return this.checkFluxo({ cfop, ncm, fluxo, cnpjFornecedor, cnpjDestinatario }, 'FLX_FUTURA_REM');
  }

  public fluxoNFeEntregaFutura(cfop: string, ncm?: string, fluxo?: string, cnpjFornecedor?: string, cnpjDestinatario?: string): boolean {
    return this.fluxoNFeFuturaFatura(cfop, ncm, fluxo, cnpjFornecedor, cnpjDestinatario) || this.fluxoNFeFuturaRemessa(cfop, ncm, fluxo, cnpjFornecedor, cnpjDestinatario);
  }

  public fluxoNFeTriangFatura(cfop: string, ncm?: string, fluxo?: string, cnpjFornecedor?: string, cnpjDestinatario?: string): boolean {
    return this.checkFluxo({ cfop, ncm, fluxo, cnpjFornecedor, cnpjDestinatario }, 'FLX_TRIANG_FAT');
  }

  public fluxoNFeTriangRemessa(cfop: string, ncm?: string, fluxo?: string, cnpjFornecedor?: string, cnpjDestinatario?: string): boolean {
    return this.checkFluxo({ cfop, ncm, fluxo, cnpjFornecedor, cnpjDestinatario }, 'FLX_TRIANG_REM');
  }

  public fluxoNFeTriangulacao(cfop: string, ncm?: string, fluxo?: string, cnpjFornecedor?: string, cnpjDestinatario?: string): boolean {
    return this.fluxoNFeTriangFatura(cfop, ncm, fluxo, cnpjFornecedor) || this.fluxoNFeTriangulacao(cfop, ncm, fluxo, cnpjFornecedor, cnpjDestinatario);
  }
}

import { catchError, shareReplay, switchMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of, timer } from "rxjs";
import { map, tap } from "rxjs/operators";
import { A } from "@angular/cdk/keycodes";

@Injectable({
  providedIn: "root",
})
export class ErpService {
  private erpCheckInterval: any;
  private cacheDadosImpostos = {};
  private cacheDireitosFiscais = {};
  private cacheTiposImpostos = {};

  _cnpjFornecedor = {};
  _cnpjFilial = {};

  statusErp: CommErp;

  constructor(
    private _http: HttpClient,
  ) { }

  /**
   * Buscar informações sobre o fornecedor para validar o CNPJ
   * @param cnpj CNPJ do fornecedor
   */
  validarCnpjFornecedor(cnpj: string, tipo_processo: string) {
    const url = `api/cliente/fornecCNPJ`;
    const filtro = { cnpj, tipo_processo };

    return timer(500).pipe(
      switchMap(() => {
        return this._http.post(url, filtro).pipe(
          catchError((err) => {
            return of(err.error);
          }),
          map(
            (dados) => {
              const success = !!(dados["cnpj"] && dados["codmun"] && dados["razaosocial"] && !dados["ERRO"]);

              if (dados["ERRO"]?.indexOf("Não encontrado nenhum fornecedor para") >= 0) {
                dados["ERRO"] = null;
              }
              return Object.assign({ success }, dados)
            })
        );
      })
    );
  }

  async buscarCnpjFornecedor(cnpj: string, tipo_processo: string) {
    // Verifica se o CNPJ está em cache
    if (this._cnpjFornecedor[cnpj]) {
      return this._cnpjFornecedor[cnpj];
    }

    let url = "api/cliente/fornecCNPJ";
    let filtro = {
      cnpj,
      tipo_processo,
    };

    return this._http.post(url, JSON.stringify(filtro)).toPromise();
  }

  /**
   * Busca informações para validar o cnpj da filial
   * @param cnpj CNPJ da filial
   */
  validarCnpjFilial(cnpj: string) {
    const url = `api/cliente/filialCNPJ`;
    const filtro = { cnpj };

    return timer(500).pipe(
      switchMap(() => {
        return this._http.post(url, filtro).pipe(
          catchError((err) => {
            return of(err.error);
          }),
          map(
            (dados) => {
              const success = !!(dados["cnpj"] && dados["codmun"] && dados["razaosocial"] && !dados["ERRO"]);

              if (dados["ERRO"]?.indexOf("Não encontrado nenhum fornecedor para") >= 0) {
                dados["ERRO"] = null;
              }
              return Object.assign({ success }, dados);
            }
          )
        );
      })
    );
  }

  async buscarCnpjFilial(cnpj: string) {
    // Verifica se o CNPJ está em cache
    if (this._cnpjFilial[cnpj]) {
      return this._cnpjFilial[cnpj];
    }

    let url = "api/cliente/filialCNPJ";
    let filtro = {
      cnpj: cnpj,
    };

    return this._http.post(url, JSON.stringify(filtro)).toPromise();
  }

  buscaPedidos(filtro): Observable<any> {
    let url = `api/v2/erp/pedido?destinatarioCnpj=${filtro.destinatarioCnpj}&tomadorCNPJ=${filtro.tomadorCNPJ}&dataCorte=${filtro.dataCorte}`;

    if (filtro.tipoDocumento) {
      url += `&tipoDocumento=${filtro.tipoDocumento}`;
    }

    if (filtro.remetenteDocumento) {
      url += `&remetenteDocumento=${filtro.remetenteDocumento}`;
    }

    if (filtro.pedidos) {
      url += `&pedidos=${JSON.stringify(filtro.pedidos)}`;
    }

    //Busca Pedidos
    return this._http.get(url);
  }

  buscaRequisitantes(filtro): Observable<any> {
    let url = "api/v2/erp/requisitantes";
    let hdr = new HttpHeaders({ "Content-Type": "application/json" });
    hdr.append("Accept", "application/json");

    return this._http.post(url, JSON.stringify(filtro), { headers: hdr });
  }

  buscarUsuario(filtro): Observable<any> {
    let url = `api/v2/erp/usuario/${filtro.email}`;

    //Busca Pedidos
    return this._http.get(url);
  }

  simulaValoresPedidos(filtro): Observable<any> {
    let url = "api/v2/erp/escrituracao";
    let hdr = new HttpHeaders({ "Content-Type": "application/json" });
    hdr.append("Accept", "application/json");

    return this._http.post(url, JSON.stringify(filtro), { headers: hdr });
  }

  escrituraServico(filtro): Observable<any> {
    let url = "api/v2/erp/escrituracao";
    let hdr = new HttpHeaders({ "Content-Type": "application/json" });
    hdr.append("Accept", "application/json");

    return this._http.post(url, JSON.stringify(filtro), { headers: hdr });
  }

  aprovaServico(pedido): Observable<any> {
    let url = "api/v2/erp/recebimento";
    let hdr = new HttpHeaders({ "Content-Type": "application/json" });
    hdr.append("Accept", "application/json");

    return this._http.post(url, JSON.stringify(pedido), { headers: hdr });
  }

  geraMigosNfe(docs: any): Observable<any> {
    let url = `api/v2/erp/contagem`;
    // Se não houver data de lançamento, o backend usará a data atual
    return this._http.post(url, docs);
  }

  geraNr(comparacaoPedido): Observable<any> {
    let url = "api/v2/erp/delivery";
    let hdr = new HttpHeaders({ "Content-Type": "application/json" });
    hdr.append("Accept", "application/json");

    return this._http.post(url, JSON.stringify(comparacaoPedido), {
      headers: hdr,
    });
  }

  verificaSincroniaAcompanhamentoErp(uuid_processo: string): Promise<any> {
    let url = `api/v2/erp/sincronizar/acompanhamento/${uuid_processo}`;

    //Busca Pedidos
    return this._http.get(url).toPromise();
  }

  verificaSincroniaAprovacaoErp(uuid_processo: string): Promise<any> {
    let url = `api/v2/erp/sincronizar/aprovacao/${uuid_processo}`;

    //Busca Pedidos
    return this._http.get(url).toPromise();
  }
  /**
   * Verificar se requisitante existe no ERP
   * @param email email do requisitante
   */
  async checkRequisitanteErp(email) {
    // Objeto para busca
    let filtro = {
      email,
    };
    try {
      // Realizar busca pelo requisitante no ERP
      let resp = await this.buscarUsuario(filtro).toPromise();

      // Se não encontrou o usuário
      if (
        resp &&
        resp.MESSAGE.length &&
        resp.MESSAGE[0].MESSAGE ==
        "Não existe usuário no ERP associado ao e-mail."
      ) {
        return { status: "notFound", detail: null };
      }
      // Se ocorreu algum problema
      if (resp && resp.MESSAGE.length) {
        return { status: "fail", detail: resp.MESSAGE[0].MESSAGE };
      }

      // Monta objeto com os dados que vieram do ERP
      const novoRequisitante = {
        nome: resp.NOME,
        usuario: resp.IDUSER,
        email,
      };

      // Retorna os dados
      return { status: "ok", detail: novoRequisitante };
    } catch (err) {
      return { status: "fail", detail: err.message };
    }
  }

  /**
   * Testa a conexão com o ERP
   * @param status Status da última tentativa de conexão com ERP
   */
  async checkConexaoErp(status: string = null): Promise<void> {
    switch (status) {
      // Se status é ok, emite evento de status ok
      case "ok":
        this.statusErp = { status, msg: "" };
        if (this.erpCheckInterval) {
          clearInterval(this.erpCheckInterval);
        }
        this.erpCheckInterval = null;
        return;

      // Se status é conectando, emite evento de status conectando
      case "conectando":
        this.statusErp = { status, msg: "" };
        return;

      // Se status é de erro, testa a conexão
      case "erro":
        try {
          // Tenta se comunicar com o ERP
          const url = `api/v2/erp/checkComunicacaoErp`;
          await this._http.get(url).toPromise();

          // Se teve sucesso, informa ao app component que está ok
          this.statusErp = { status: "ok", msg: "" };
        } catch (err) {
          // Em caso de erro, emite evento informando o erro
          const errStr = 'Erro de conexão com ERP';
          if (err.error && err.error.toString() != "[object Object]" &&
            err.error.toString() != "[object ProgressEvent]") {
            if (err.error && err.error.toString() != "[object Object]") {
              this.statusErp = { status: 'erro', msg: err.error || errStr };
            } else if (err.error.toString() != "[object ProgressEvent]") {
              this.statusErp = { status: 'erro', msg: err.message || errStr };
            }
          } else {
            const msgErr1 = err.message ? errStr + " - " + err.message : errStr + " - ";
            const msgErr2 = err.error.error ? err.error.error.message : "";

            this.statusErp = { status: 'erro', msg: msgErr1 + " - " + msgErr2 };

          }

          if (this.erpCheckInterval) {
            break;
          }
          this.erpCheckInterval = setInterval(async () => {
            await this.checkConexaoErp("erro");
          }, 30_000);
        }
    }
  }

  /**
   * Recupera informações de material do ERP
   */
  public getDadosMaterial(material: string): Observable<any> {
    const url = `api/v2/erp/material/${encodeURIComponent(material.trim())}`;
    return this._http.get(url);
  }

  /**
   * Recupera dados de impostos do ERP
   */
  private getDadosImposto(grupoImposto: string): Observable<any> {
    if (!this.cacheDadosImpostos[grupoImposto]) {
      const url = `api/v2/erp/imposto/${encodeURIComponent(grupoImposto.trim())}/dados`;
      this.cacheDadosImpostos[grupoImposto] = this._http.get<Array<any>>(url).pipe(
        shareReplay(),
      );
    }
    return this.cacheDadosImpostos[grupoImposto];
  }

  /**
   * Recupera direitos fiscais do ERP
   */
  public getDireitosFiscais(grupoImposto: string): Observable<Array<any>> {
    if (!this.cacheDireitosFiscais[grupoImposto]) {
      this.cacheDireitosFiscais[grupoImposto] = this.getDadosImposto(grupoImposto).pipe(
        map(result => result.direitosFiscais.sort((a, b) => {
          return a.direitoFiscal.localeCompare(b.tipoImposto);
        })),
        shareReplay(),
      );
    }
    return this.cacheDireitosFiscais[grupoImposto];
  }

  /**
   * Recupera tipos de impostos do ERP
   */
  public getTiposImpostos(grupoImposto: string): Observable<Array<any>> {
    if (!this.cacheTiposImpostos[grupoImposto]) {
      this.cacheTiposImpostos[grupoImposto] = this.getDadosImposto(grupoImposto).pipe(
        map(result => result.tiposImpostos.sort((a, b) => {
          return a.tipoImposto.localeCompare(b.tipoImposto);
        })),
        shareReplay(),
      );
    }
    return this.cacheTiposImpostos[grupoImposto];
  }

  /**
   * Recupera motivos de estorno
   */
  public getMotivosEstorno(): Observable<any> {
    const url = `api/v2/erp/motivos-estorno`;
    return this._http.get(url);
  }

  /**
   * Valida CFOP no ERP
   */
  public validaCfop(cfop: string): Observable<any> {
    const url = `api/v2/erp/cfop/${encodeURIComponent(cfop.trim())}`;
    return this._http.get(url);
  }

  /**
   * Valida Unidade de Medida no ERP
   */
  public validaUnidadeMedida(unidadeErp: string, unidadeDocumento: string, material: string,
    cnpjEmitente: string, cnpjDestinatario: string): Observable<any> {
    return this._http.post('api/v2/erp/unidade-medida', {
      unidadeErp, unidadeDocumento, material, cnpjEmitente, cnpjDestinatario
    });
  }
}

export class CommErp {
  status: string;
  msg: string;
}
